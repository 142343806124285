import { Avatar, Button, Col, Divider, Layout, Row, Space } from "antd";
import { Popup, WarningModal } from "../../components";
import { LangGlobal, LoggingOut } from "../../assets/svg";
import header from "./header.module.less";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../i18n";
import { HLog } from "../../helpers";
import { keys, paths } from "../../constants";
import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import ModalHangCho from "../../pages/QuanLyTiepDon/ModalHangCho/ModalHangCho";
import { useAuth, useChangeLanguage } from "../../hooks";
import { routeApp } from "../../constants/keys";
import style from "./header.module.less";
import { setRoute } from "../../ducks/slices/authSlice";
import Icon from "@ant-design/icons/lib/components/Icon";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Chatbot } from "features";
import FastSwitchButtons from "components_v2/FastSwitchButtons";
import TabHeader from "components/Tabs/TabHeader";
import { useSubItem } from "./subItems";
import { Images } from "../../components";

const Header = ({ className }) => {
  const subItems = useSubItem();
  const typeShowModule = useRef(null);

  const { user: userProfile, versionWeb: version_web } = useSelector((state) => state.auth);

  const titlePage = useSelector((state) => state.titlePage);
  const currentLang = localStorage.getItem("i18nextLng");
  const hangChoRef = useRef();
  const versionRef = useRef();
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { onChangeLanguage } = useChangeLanguage();
  const storageVersion = async () => {
    try {
      let res = await localStorage.setItem(keys.version_web, JSON.stringify(userProfile.version_web));
      return res;
    } catch (error) {
      HLog(error);
    }
  };

  useEffect(() => {
    const checkVersion = async () => {
      try {
        if (version_web) {
          // check version trong store
          let currentVersion = await localStorage.getItem(keys.version_web);
          if (currentVersion) {
            currentVersion = await JSON.parse(currentVersion);
          }

          if (!currentVersion) {
            storageVersion();
          } else {
            if (currentVersion.TEN_VERSION !== version_web.TEN_VERSION) {
              // versionRef.current.open();
            }
          }
        }
      } catch (error) {
        HLog(error);
      }
    };
    checkVersion();
  }, [userProfile]);

  const handleLogoutRefresh = () => {
    storageVersion();
    sessionStorage.setItem(keys.isReload, "1");
    // logout();
    if (version_web && version_web.RE_LOGIN) {
      logout();
    } else {
      window.location.reload()
    }
  };

  const dispatch = useDispatch();
  const handleChangeLanguage = (val) => {
    onChangeLanguage(val);
  };

  useEffect(() => {
    if (window.location.pathname.includes(routeApp.CIS.toLowerCase())) {
      dispatch(setRoute(routeApp.CIS));
    }
  }, []);

  useEffect(() => {
    typeShowModule.current = true;
  }, []);

  const checkRenderSubItems = (pathName = '') => {
    let items = subItems.find(item => pathName.includes(item.key))
    return items?.tabs ? items.tabs : []
  }

  const styleInLine =  useMemo(() => {
    if (pathname?.includes(paths.quan_ly_kham_benh) || pathname?.includes(paths.quan_ly_dv_chi_dinh)) {
      return{ minWidth: 330 }
    }
    return{}
  })

  return (
    <Layout.Header className={cn(header["container"], className)}>
      <div className={cn(header["wrapper-left"])} style={styleInLine}>
        <h3 className={header["name"]}>{titlePage.title}</h3>
        {titlePage.totalResult > 0 && <div className={header["total-num"]}>{titlePage.totalResult}</div>}
      </div>
      <TabHeader items={checkRenderSubItems(pathname)} isHeader />
      <div className={cn(header["wrapper"], header["grow"])}>
      </div>
      <FastSwitchButtons />

      <div style={{ transform: "scale(0.5)", paddingTop: 30 }}>
        <Chatbot from={'CIS'} key={'CIS1111111'} floatBtn={false} styleWrapper={{ transform: 'translateX(10px)' }} />
      </div>

      <Popup
        style={{ marginRight: 16 }}
        content={
          <div className={header["menu"]}>
            <Row align="middle" className={header["user"]} wrap={false}>
              <Col>
                <Avatar size={30} className={header["avatar"]} src={userProfile?.ANH_DAI_DIEN ? <Images imgId={userProfile.ANH_DAI_DIEN}/> : undefined} icon={<UserOutlined />}>
                </Avatar>
              </Col>

              <Col style={{ marginLeft: 10, marginRight: 10 }}>
                <div className={header["name"]}>{userProfile.HO + " " + userProfile.TEN}</div>
              </Col>
              <Col>
                <DownOutlined />
              </Col>
            </Row>

            <Row gutter={6} className={cn(header["menu-item"])} onClick={logout}>
              <Col>
                <Icon component={LoggingOut} />
              </Col>
              <Col>{i18n.t(languageKeys.common_Dang_xuat)}</Col>
            </Row>

            <Popup
              popupClassName={header["sub-popup"]}
              content={
                <div className={header["menu"]}>
                  <Row
                    className={cn(header["menu-item"], currentLang === languages.tieng_viet && header["active"])}
                    onClick={() => handleChangeLanguage(languages.tieng_viet)}
                  >
                    <Col></Col>
                    <Col>{i18n.t(languageKeys.ngon_ngu_tieng_viet)}</Col>
                  </Row>

                  <Row
                    className={cn(header["menu-item"], currentLang === languages.tieng_anh && header["active"])}
                    onClick={() => handleChangeLanguage(languages.tieng_anh)}
                  >
                    <Col></Col>
                    <Col>{i18n.t(languageKeys.ngon_ngu_tieng_anh)}</Col>
                  </Row>
                </div>
              }
            >
              <Row className={cn(header["menu-item"])} gutter={6}>
                <Col>
                  <Icon component={LangGlobal} />
                </Col>
                <Col>{i18n.t(languageKeys.common_Doi_ngon_ngu)}</Col>
              </Row>
            </Popup>
            <div style={{ padding: "0 14px" }}>
              <Divider dashed style={{ margin: 0 }} />
            </div>
            <div className={header["version"]}>Version: {keys.VERSION}</div>
          </div>
        }
      >
        <div className={cn(header["wrapper"])}>
          <Space size={10} className={style["user"]}>
            <Avatar size={30} className={header["avatar"]} src={userProfile?.ANH_DAI_DIEN ? <Images imgId={userProfile.ANH_DAI_DIEN}/> : undefined} icon={<UserOutlined />}>
            </Avatar>
          </Space>
        </div>
      </Popup>

      <ModalHangCho ref={hangChoRef} />
      <WarningModal
        ref={versionRef}
        title={i18n.t(languageKeys.cap_nhat_phan_mem)}
        content={version_web?.RE_LOGIN ? i18n.t(languageKeys.content_update_version) : i18n.t(languageKeys.content_update_version_reload)}
        maskClosable={false}
        width={450}
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleLogoutRefresh} type="primary">
              {i18n.t(languageKeys.common_ok)}
            </Button>
          </div>
        }
      />
    </Layout.Header>
  );
};

export default Header;
