import Keycloak from "keycloak-js";
import apiServices from "../config/apiServices";

// const keycloak = new Keycloak({
//   url: apiServices.KEYCLOAK_URL,
//   realm: apiServices.KEYCLOAK_REALM,
//   clientId: apiServices.KEYCLOAK_CLIENT_ID,
//   authenticated:true,
// });

// export default keycloak;

// Function to get the Keycloak configuration based on the current domain
const getKeycloakConfig = () => {
  const currentDomain = window.location.hostname;

  let keycloakConfig;

  // Check the domain and set the Keycloak server, realm, and clientId accordingly
  if (currentDomain.includes("phongkhamso")) {
    keycloakConfig = {
      url: apiServices.KEYCLOAK_URL,
      realm: apiServices.KEYCLOAK_REALM,
      clientId: apiServices.KEYCLOAK_CLIENT_ID,
      authenticated:true,
    };
  } else {
    // Default configuration
    keycloakConfig = {
      url: apiServices.KEYCLOAK_URL,
      realm: apiServices.KEYCLOAK_REALM,
      clientId: apiServices.KEYCLOAK_CLIENT_ID,
      authenticated:true,
    };
  }

  return keycloakConfig;
};

// Initialize Keycloak with dynamic config based on the current domain
const keycloak = new Keycloak(getKeycloakConfig());

export default keycloak;