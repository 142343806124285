import { Tooltip } from "antd";
import { paths } from "../../constants";
import i18n, { languageKeys } from "../../i18n";


export const useSubItem = () => {

    const tabChiDinhCls =  {
    key: paths.quan_ly_dv_chi_dinh,
    label: (
        <Tooltip title={i18n.t(languageKeys.thuc_hien_can_lam_sang)} placement="bottom">
            <div style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: 250, textOverflow: "ellipsis" }}>
            {i18n.t(languageKeys.thuc_hien_can_lam_sang)}
            </div>
        </Tooltip>
        ),
    }   

    const subItems = [
        // Qly tiếp đón
        {
            key: paths.quan_ly_tiep_don,
            tabs: [
                {
                    key: paths.quan_ly_tiep_don,
                    label: i18n.t(languageKeys.label_Tiep_don),
                },
                {
                    key: paths.quan_ly_cuoc_hen_sap_toi,
                    label: i18n.t(languageKeys.label_quan_ly_lich_hen),
                }
            ]
        },
        {
            key: paths.quan_ly_cuoc_hen_sap_toi,
            tabs: [
                {
                    key: paths.quan_ly_tiep_don,
                    label: i18n.t(languageKeys.label_Tiep_don),
                },
                {
                    key: paths.quan_ly_cuoc_hen_sap_toi,
                    label: i18n.t(languageKeys.label_quan_ly_lich_hen),
                }
            ]
        },
        // Qly thanh toán
        {
            key: paths.quan_ly_dich_vu_can_thanh_toan,
            tabs: [
                {
                    key: paths.quan_ly_dich_vu_can_thanh_toan,
                    label: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
                },
                {
                    key: paths.quan_ly_tam_ung,
                    label: i18n.t(languageKeys.quan_ly_tam_ung),
                },
                {
                    key: paths.quan_ly_cong_no,
                    label: i18n.t(languageKeys.quan_ly_cong_no),
                },
                {
                    key: paths.quan_ly_so_hoa_don,
                    label: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
                }
            ]
        },
        {
            key: paths.quan_ly_tam_ung,
            tabs: [
                {
                    key: paths.quan_ly_dich_vu_can_thanh_toan,
                    label: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
                },
                {
                    key: paths.quan_ly_tam_ung,
                    label: i18n.t(languageKeys.quan_ly_tam_ung),
                },
                {
                    key: paths.quan_ly_cong_no,
                    label: i18n.t(languageKeys.quan_ly_cong_no),
                },
                {
                    key: paths.quan_ly_so_hoa_don,
                    label: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
                }
            ]
        },
        {
            key: paths.quan_ly_cong_no,
            tabs: [
                {
                    key: paths.quan_ly_dich_vu_can_thanh_toan,
                    label: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
                },
                {
                    key: paths.quan_ly_tam_ung,
                    label: i18n.t(languageKeys.quan_ly_tam_ung),
                },
                {
                    key: paths.quan_ly_cong_no,
                    label: i18n.t(languageKeys.quan_ly_cong_no),
                },
                {
                    key: paths.quan_ly_so_hoa_don,
                    label: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
                }
            ]
        },
        {
            key: paths.quan_ly_so_hoa_don,
            tabs: [
                {
                    key: paths.quan_ly_dich_vu_can_thanh_toan,
                    label: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
                },
                {
                    key: paths.quan_ly_tam_ung,
                    label: i18n.t(languageKeys.quan_ly_tam_ung),
                },
                {
                    key: paths.quan_ly_cong_no,
                    label: i18n.t(languageKeys.quan_ly_cong_no),
                },
                {
                    key: paths.quan_ly_so_hoa_don,
                    label: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
                }
            ]
        },
        {
            key: paths.thong_tin_so_hoa_don_nId,
            tabs: [
                {
                    key: paths.quan_ly_dich_vu_can_thanh_toan,
                    label: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
                },
                {
                    key: paths.quan_ly_tam_ung,
                    label: i18n.t(languageKeys.quan_ly_tam_ung),
                },
                {
                    key: paths.quan_ly_cong_no,
                    label: i18n.t(languageKeys.quan_ly_cong_no),
                },
                {
                    key: paths.quan_ly_so_hoa_don,
                    label: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
                }
            ]
        },
        // Qly khám bệnh
        {
            key: paths.quan_ly_kham_benh,
            tabs: [
                {
                    key: paths.quan_ly_kham_benh,
                    label: i18n.t(languageKeys.kham_benh),
    
                },
                {
                    ...tabChiDinhCls
                },
            ]
        },
        {
            key: paths.quan_ly_dv_chi_dinh,
            tabs: [
                {
                    key: paths.quan_ly_kham_benh,
                    label: i18n.t(languageKeys.kham_benh),
    
                },
                {
                    ...tabChiDinhCls
                },
            ]
        },
        // Qly dược ngoại trú
        {
            key: paths.ban_thuoc,
            tabs: [
                {
                    key: paths.ban_thuoc,
                    label: i18n.t(languageKeys.menu_Ban_thuoc),
                },
                {
                    key: paths.ton_kho,
                    label: i18n.t(languageKeys.field_ton_kho),
                },
                {
                    key: paths.quan_ly_phieu_nhap_xuat,
                    label: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
                },
                {
                    key: paths.nha_cung_cap,
                    label: i18n.t(languageKeys.menu_Nha_cung_cap),
                },
            ]
        },
        {
            key: paths.ton_kho,
            tabs: [
                {
                    key: paths.ban_thuoc,
                    label: i18n.t(languageKeys.menu_Ban_thuoc),
                },
                {
                    key: paths.ton_kho,
                    label: i18n.t(languageKeys.field_ton_kho),
                },
                {
                    key: paths.quan_ly_phieu_nhap_xuat,
                    label: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
                },
                {
                    key: paths.nha_cung_cap,
                    label: i18n.t(languageKeys.menu_Nha_cung_cap),
                },
            ]
        },
        {
            key: paths.quan_ly_phieu_nhap_xuat,
            tabs: [
                {
                    key: paths.ban_thuoc,
                    label: i18n.t(languageKeys.menu_Ban_thuoc),
                },
                {
                    key: paths.ton_kho,
                    label: i18n.t(languageKeys.field_ton_kho),
                },
                {
                    key: paths.quan_ly_phieu_nhap_xuat,
                    label: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
                },
                {
                    key: paths.nha_cung_cap,
                    label: i18n.t(languageKeys.menu_Nha_cung_cap),
                },
            ]
        },
        {
            key: paths.nha_cung_cap,
            tabs: [
                {
                    key: paths.ban_thuoc,
                    label: i18n.t(languageKeys.menu_Ban_thuoc),
                },
                {
                    key: paths.ton_kho,
                    label: i18n.t(languageKeys.field_ton_kho),
                },
                {
                    key: paths.quan_ly_phieu_nhap_xuat,
                    label: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
                },
                {
                    key: paths.nha_cung_cap,
                    label: i18n.t(languageKeys.menu_Nha_cung_cap),
                },
            ]
        },
        // RIS/PACS
        {
            key: paths.mau_mo_ta_cdha,
            tabs: [
                {
                    key: paths.mau_mo_ta_cdha,
                    label: i18n.t(languageKeys.menu_mau_mo_ta_cdha),
    
                },
                {
                    key: paths.tra_ket_qua_cdha,
                    label: i18n.t(languageKeys.menu_tra_kq_cdha),
                },
                {
                    key: "https://live.draid.ai/",
                    label: "PACS",
                }
            ]
        },
        {
            key: paths.tra_ket_qua_cdha,
            tabs: [
                {
                    key: paths.mau_mo_ta_cdha,
                    label: i18n.t(languageKeys.menu_mau_mo_ta_cdha),
    
                },
                {
                    key: paths.tra_ket_qua_cdha,
                    label: i18n.t(languageKeys.menu_tra_kq_cdha),
                },
                {
                    key: "https://live.draid.ai/",
                    label: "PACS",
                }
            ]
        },
        // Qly gói dịch vụ
        {
            key: paths.quan_ly_dk_goi_dv,
            tabs: [
                {
                    key: paths.quan_ly_dk_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_dk_goi_dv),
                },
                {
                    key: paths.quan_ly_su_dung_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_su_dung_goi_dv),
                },
                {
                    key: paths.danh_sach_goi_dv,
                    label: i18n.t(languageKeys.danh_sach_goi_dv),
                },
            ]
        },
        {
            key: paths.quan_ly_su_dung_goi_dv,
            tabs: [
                {
                    key: paths.quan_ly_dk_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_dk_goi_dv),
                },
                {
                    key: paths.quan_ly_su_dung_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_su_dung_goi_dv),
                },
                {
                    key: paths.danh_sach_goi_dv,
                    label: i18n.t(languageKeys.danh_sach_goi_dv),
                },
            ]
        },
        {
            key: paths.danh_sach_goi_dv,
            tabs: [
                {
                    key: paths.quan_ly_dk_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_dk_goi_dv),
                },
                {
                    key: paths.quan_ly_su_dung_goi_dv,
                    label: i18n.t(languageKeys.quan_ly_su_dung_goi_dv),
                },
                {
                    key: paths.danh_sach_goi_dv,
                    label: i18n.t(languageKeys.danh_sach_goi_dv),
                },
            ]
        },
    ]
    return subItems
}